import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const Button = ({ onClick }) => <Container onClick={() => onClick()}>BUTTON</Container>;

const Container = styled.div`
    display: inline-block;
    flex: none;
    background: ${({ theme }) => theme.colors.grey.dark};
`;

Button.propTypes = {
    onClick: PropTypes.any
}
