import React from "react";

import { Page, Container } from "@common/website/components/shared/layout";
import { Button } from "@common/website/components/shared/button";

class Office extends React.Component {
  componentDidMount() {
    console.log("OFFICE MOUNT")
  }
  render() {
    return (
      <Button onClick={() => alert("TEST")}>CLIIICK</Button>
    );
  }
}

export default Office;
